import { Component, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService, BasketService, LanguageService, NotificationsService, GlobalService } from 'src/app/services';

export let browserRefresh = false;
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {

    subscription: Subscription;

    public defaultPages = [
        // { title: 'FAVORITES.TITLE', url: '/favorites'},
        { title: 'BASKET.TITLE', url: '/basket' },
        { title: 'ACCOUNT.TITLE', url: '/account' },
        { title: 'CONTACT.TITLE', url: '/contact' },
    ];

    constructor(
        private router: Router,
        private platform: Platform,
        private languageService: LanguageService,
        private authService: AuthService,
        public globalvar: GlobalService,
        public basketService: BasketService,
        public notificationsService: NotificationsService
    ) {
        //android
        document.addEventListener('message', (response: any) => {
            this.handleExpoListener(response);
        });
        //iOS
        window.addEventListener('message', (response: any) => {
            this.handleExpoListener(response);
        });


        this.initializeApp();
    }

    ngOnInit(): void {
        this.subscribe();
    }

    ionViewWillLeave() {
        this.subscribe();
    }

    handleExpoListener(response) {
        if (response && response.data) {
            response = JSON.parse(response.data) ?? null;

            if (response) {
                // Updates the configuration
                if (response.type === 'config') {
                    const oldDeviceToken = this.globalvar.expoConfig.deviceToken ?? null;
                    this.globalvar.expoConfig = response.data;
                    if (!this.globalvar.expoConfig.deviceToken || oldDeviceToken !== response.data.deviceToken) {
                        this.notificationsService.initPush();
                    }
                }

                // Handles the active state for the app
                if (response.type === 'app-state') {
                    if (response.state.match(/inactive|background/)) {
                        // app is active again
                    }
                }

                // Handles notifications
                if (response.type === 'notification') {
                    this.notificationsService.notificationReceived(response.data);
                }
            }
        }
    }

    subscribe() {
        this.subscription = this.router.events.subscribe(async (event) => {
            if (event instanceof NavigationStart) {
                browserRefresh = !this.router.navigated;
                await this.basketService.basketData();
                await this.authService.getToken();
                await this.authService.getUser();
            }
        });
    }

    initializeApp() {
        this.platform.ready().then(async () => {
            // this.statusBar.styleDefault();
            // this.splashScreen.hide();

            this.authService.getUser();
            this.languageService.setInitialAppLanguage();
        });

        this.platform.resume.subscribe(() => {
            this.authService.getUser();
        });
    }
}
